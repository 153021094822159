import React from "react";
import "./loading1.css";

const Loading1 = ({ text }) => {
  return (
    <div className="loading-container">
      <img
        src={text}
        loading="lazy"
        onContextMenu={(e) => e.preventDefault()}
        onDragStart={(e) => e.preventDefault()}
      />
    </div>
  );
};

export default Loading1;
